<template>
  <div>
    <b-row>
      <b-modal ref="my-modal" :hide-footer="true" :hide-header="true">
        <div class="card card-custom">
          <div class="card-header">
            <h3 class="card-title">
              {{ newSender ? "Create Sender" : "Update Sender" }}
            </h3>
          </div>

          <form v-on:submit.prevent>
            <div class="card-body">
              <div class="form-group mb-8"></div>
              <div class="form-group">
                <label>Sender Caption<span class="text-danger">*</span></label>
                <input class="form-control" v-model="selectedSender.caption" />
              </div>
              <div class="form-group">
                <label>Sender Value<span class="text-danger">*</span></label>
                <input class="form-control" v-model="selectedSender.value" />
              </div>
            </div>
            <div
              class="card-footer"
              style="display: flex; justify-content: flex-end;"
            >
              <button
                type="submit"
                class="btn btn-primary mr-2"
                :disabled="disableSubmit()"
                @click="submitSender"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </b-modal>

      <b-col lg="12">
        <div
          class="card card-custom gutter-b card-stretch card-shadowless bg-light"
        >
          <!--begin::Header-->
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Senders
              </span>

              <span class="text-muted mt-3 font-weight-bold font-size-sm">
                These are all system senders
              </span>
            </h3>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <!--begin::Table-->
            <div class="table-responsive">
              <table class="table table-borderless table-vertical-center">
                <thead>
                  <tr>
                    <th class="p-0" style="width: 50px"></th>
                    <th class="p-0" style="min-width: 100px"></th>
                    <th class="p-0" style="min-width: 100px"></th>
                  </tr>
                </thead>
                <tbody>
                  <!--ADD BUTTON-->
                  <tr>
                    <td class="pl-0"></td>
                    <td class="pl-0"></td>
                    <td class="text-right pr-0">
                      <a
                        href="#"
                        @click="addSender"
                        class="btn btn-icon btn-light btn-sm"
                      >
                        <span class="svg-icon svg-icon-md svg-icon-primary">
                          <!--begin::Svg Icon-->
                          <inline-svg
                            src="media/svg/icons/Communication/Add-sender.svg"
                          ></inline-svg>
                          <!--end::Svg Icon-->
                        </span>
                      </a>
                    </td>
                  </tr>

                  <template v-for="(item, i) in senders">
                    <tr v-bind:key="i">
                      <td class="pl-0 py-4">
                        <div class="symbol symbol-50 symbol-light mr-1">
                          <span class="symbol-label">
                            <img
                              :src="picture"
                              class="h-50 align-self-center"
                              alt=""
                            />
                          </span>
                        </div>
                      </td>
                      <td class="pl-0">
                        <a
                          class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >
                          {{ item.caption }}
                        </a>
                        <div>
                          <a class="text-muted font-weight-bold">
                            {{ item.value }}
                          </a>
                        </div>
                      </td>
                      <td class="text-right pr-0">
                        <a
                          class="btn btn-icon btn-light btn-sm mx-3"
                          @click="editSender(item)"
                        >
                          <span class="svg-icon svg-icon-md svg-icon-primary">
                            <!--begin::Svg Icon-->
                            <inline-svg
                              src="media/svg/icons/Communication/Write.svg"
                            ></inline-svg>
                            <!--end::Svg Icon-->
                          </span>
                        </a>
                        <span
                          class="d-inline-block"
                          tabindex="0"
                          v-b-tooltip.hover.top
                        >
                          <b-link
                            class="btn btn-icon btn-light btn-sm"
                            @click="deleteSender(item)"
                          >
                            <span class="svg-icon svg-icon-md svg-icon-primary">
                              <!--begin::Svg Icon-->
                              <inline-svg
                                src="media/svg/icons/General/Trash.svg"
                              ></inline-svg>
                              <!--end::Svg Icon-->
                            </span>
                          </b-link>
                        </span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Body-->
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      senders: [],
      selectedSender: {},
      newSender: false,
    };
  },

  components: {},

  computed: {
    picture() {
      return (
        process.env.BASE_URL + "media/svg/avatars/avatar-single-orange.svg"
      );
    },
  },
  methods: {
    deleteSender(sender) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure you want to delete ${sender.caption}?`)
        .then((value) => {
          if (value === true) {
            ApiService.delete(`senders/${sender.id}`).then((response) => {
              this.getSenders();
            });
          } else {
            console.log("Sender Rejected");
          }
        })
        .catch((err) => {});
    },
    getSenders() {
      ApiService.query("senders").then((response) => {
        this.senders = response.data.sort((a, b) =>
          a.caption > b.caption ? 1 : -1
        );
      });
    },
    editSender(sender) {
      ApiService.get(`senders/${sender.id}`).then((res) => {
        this.selectedSender = res.data;

        this.newSender = false;

        this.$refs["my-modal"].show();
      });
    },
    addSender() {
      this.selectedSender = new Object({
        caption: "",
        value: "",
      });

      this.newSender = true;
      this.$refs["my-modal"].show();
    },
    submitSender() {
      if (this.newSender) {
        ApiService.post(`senders`, this.selectedSender).then((res) => {
          this.getSenders();
        });
      } else {
        ApiService.put(`senders`, this.selectedSender).then((res) => {
          this.getSenders();
        });
      }
      this.$refs["my-modal"].hide();
    },
    disableSubmit() {
      if (this.selectedSender.caption === "") {
        return true;
      } else {
        return false;
      }
    },
    makeid(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" },
    ]);

    this.selectedSender = new Object({
      caption: "",
      value: "",
    });

    this.getSenders();
  },
};
</script>
